import React, { useEffect } from 'react'

import { Box, Container, Typography } from '@mui/material'

import Footer from '../components/Footer'

import Globe from '../assets/images/globe.png'
import Callum from '../assets/images/team/callum.png'
import Natalie from '../assets/images/team/natalie.png'
import Sangwoo from '../assets/images/team/sangwoo.png'

const teamMembers = [
  {
    name: 'Sangwoo Kim',
    role: 'Cofounder • Product / Design',
    pfp: Sangwoo,
  },
  {
    name: 'Natalie Romano',
    role: 'Cofounder • Eng / Tech',
    pfp: Natalie,
  },
  {
    name: 'Callum Kelpin',
    role: 'Cofounder • Business',
    pfp: Callum,
  }
]

const WhoWeAre = ({ handleLoginClick, navColor, setNavColor }) => {
  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY <= 30 && navColor !== 'transparent') setNavColor('transparent')
      else if (window.scrollY > 30 && navColor !== 'secondary.main') setNavColor('secondary.main')
    })
  }, [])

  return (
    <Box sx={{
      backgroundColor: 'primary.main',
    }}>
      <Box width='100%' sx={{
        backgroundImage: `url(${Globe})`,
        backgroundSize: 'cover',
        backgroundPositionY: '-200px',
        borderRadius: '0 0 0 100px',
        padding: {
          xs: '100px 0',
          md: '200px 0',
        },
        position: 'relative',
        height: {
          xs: '900px',
          md: '1200px',
        },
      }}>
        <Box width='100%' position='absolute' top='0' left='0' sx={{
          background: 'linear-gradient(179.99deg, #001917 0.01%, rgba(5, 37, 32, 0) 48.75%, #093029 93.69%)',
          borderRadius: '0 0 0 100px',
          height: {
            xs: '900px',
            md: '1200px',
          },
        }} />
        <Container sx={{ position: 'relative' }}>
          <Box>
            <Typography variant='h2' color='primary.main' textAlign='center' sx={{ fontFamily: 'Clash Display Bold', fontSize: { xs: '40px', md: '60px' } }}>Mission</Typography>
            <Typography mt='24px' marginX='auto' maxWidth='80%' textAlign='center' variant='h5' color='primary.main' sx={{ fontSize: { xs: '20px', md: '32px' } }}>GreenUp was born with the mission to better our environment by helping people adopt a more sustainable lifestyle.  We believe caring for the environment should be as convenient and enjoyable as a sip of coffee.</Typography>
          </Box>
          <Box sx={{ mt: { xs: '100px', md: '380px' } }}>
            <Typography variant='h2' color='primary.main' textAlign='center' sx={{ fontFamily: 'Clash Display Bold', fontSize: { xs: '40px', md: '60px' } }}>Vision</Typography>
            <Typography mt='24px' marginX='auto' maxWidth='80%' textAlign='center' variant='h5' color='primary.main' sx={{ fontSize: { xs: '20px', md: '32px' } }}>We dream of a world where sustainable shopping becomes second nature to everyone. When everyone participates in the move to more eco-friendly consumption, we’ll be able to truly make progress toward saving our planet. </Typography>
          </Box>
        </Container>
      </Box>
      <Box sx={{
         backgroundColor:'secondary.main',
      }}>
        <Box height='110px' borderRadius='0 100px 0 0' sx={{
           backgroundColor: 'primary.main',
        }} />
      </Box>
      <Box sx={{
         backgroundColor: 'background.default',
      }}>
        <Box borderRadius='0 0 100px 0' paddingBottom='110px' sx={{
           backgroundColor: 'primary.main',
        }}>
          <Container>
            <Typography variant='h2' sx={{ fontFamily: 'Clash Display Semibold', fontSize: { xs: '40px', md: '60px' } }}>Meet the Team</Typography>
            <Box mt='48px' display='flex' justifyContent='center' gap='110px' sx={{
              gap: {
                xs: '16px',
                md: '110px',
              },
            }}>
              {teamMembers.map(({ name, role, pfp }) => (
                <Box key={name}>
                  <Box sx={{
                    backgroundImage: `url(${pfp})`,
                    backgroundPosition: 'center',
                    backgroundSize: 'contain',
                    borderRadius: {
                      xs: '50%',
                      md: '100px 0',
                    },
                    height: {
                      xs: '100px',
                      md: '300px',
                    },
                    width: {
                      xs: '100px',
                      md: '300px',
                    },
                  }} />
                  <Typography mt='48px' variant='h5'>{name}</Typography>
                  <Typography>{role}</Typography>
                </Box>
              ))}
            </Box>
          </Container>
        </Box>
      </Box>
      <Footer handleLoginClick={handleLoginClick} />
    </Box>
  )
}

export default WhoWeAre