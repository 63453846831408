import React, { useEffect } from 'react'

import { Check, Mail, Layout, PlusCircle, Search, ShoppingCart } from 'react-feather'

import { Box, Button, Container, Typography, useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles';

import Footer from '../components/Footer'

import Leaf from '../assets/images/leaf.png'

const Landing = ({ navColor, setNavColor, handleLoginClick, user }) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  useEffect(() => {
    const changePoint = isMobile ? 56 : 115

    window.addEventListener('scroll', () => {
      if (window.scrollY <= changePoint && navColor !== 'transparent') setNavColor('transparent')
      else if (window.scrollY > changePoint && navColor !== 'secondary.main') setNavColor('secondary.main')
    })
  }, [])

  return (
    <Box position='relative' sx={{
      backgroundColor: 'secondary.main',
    }}>
      <Box sx={{
         backgroundColor: 'primary.main',
      }}>
        <Box width='100%' sx={{
          backgroundImage: `url(${Leaf})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          borderRadius: '0 0 100px 0',
          padding: {
            xs: '120px 0 70px',
            md: '180px 0',
          },
        }}>
          <Container>
            <Box borderRadius='100px 0' sx={{
              backgroundColor: 'primary.main',
              padding: {
                xs: '60px 32px 48px',
                md: '80px',
              },
              width: {
                xs: '100%',
                md: '820px',
              }
            }}>
              <Typography
                color='palette.text.primary'
                variant='h2'
                sx={{ fontFamily: 'Clash Display Semibold', fontSize: { xs: '40px', md: '60px' }, fontWeight: '600' }}
              >
                Sustainable shopping on autopilot
              </Typography>
              <Typography variant='h5' sx={{ fontSize: { xs: '20px', md: '32px' }, mt: { xs: '8px', md: '32px' } }}>Loving Earth couldn't be made easier.</Typography>
              <Button href={user ? '/dashboard' : undefined} onClick={!user ? handleLoginClick : () => {}} sx={{
                backgroundColor: 'secondary.main',
                borderRadius: '100px 0',
                height: {
                  xs: '48px',
                  md: '80px',
                },
                mt: {
                  xs: '32px',
                  md: '100px',
                },
                width: '100%',
                '&:hover': {
                  backgroundColor: 'secondary.main',
                  opacity: '80%',
                }
              }}>
                {user ? (
                  <Typography variant='button' textTransform='capitalize' color='text.secondary' sx={{ fontSize: { xs: '24px', md: '32px' } }}>
                    Welcome back
                  </Typography>
                ) : (
                  <Typography variant='button' textTransform='capitalize' color='text.secondary' sx={{ fontSize: { xs: '24px', md: '32px' } }}>
                    Sign Up
                  </Typography>
                )
                }
              </Button>
            </Box>
          </Container>
        </Box>
      </Box>
      <Box borderRadius='100px 0 0 0' sx={{
         backgroundColor: 'primary.main',
      }}>
        <Container>
          <Box sx={{ padding: { xs: '60px 32px 0', md: '110px' } }}>
            <Typography variant='h1' sx={{ fontFamily: 'Clash Display Semibold', fontSize: { xs: '40px', md: '80px' } }}>How it works</Typography>
            <Box display='flex' alignItems='center' sx={{
              flexDirection: {
                xs: 'column',
                md: 'row',
              },
              mt: {
                xs: '32px',
                md: '80px',
              },
              gap: {
                xs: '32px',
                md: '120px',
              },
            }}>
              <Box sx={{ maxWidth: { xs: '100%', md: '60%' } }}>
                <Typography variant='h3' sx={{ fontSize: { xs: '24px', md: '48px' } }}>Link your email</Typography>
                <Typography variant='h5' mt='20px' sx={{ fontSize: { xs: '16px', md: '24px' } }}>
                  We look at past orders from your inbox to understand your needs. Our technology is designed to only look at your shopping history. If you prefer, you have an option to forward select emails.
                </Typography>
              </Box>
              <Box position='relative' borderRadius='100px 0' sx={{
                backgroundColor:'secondary.main',
                height: {
                  xs: '100px',
                  md: '280px',
                },
                minWidth: {
                  xs: '200px',
                  md: '350px',
                },
                width: {
                  xs: '200px',
                  md: '700px',
                },
                '.mail-icon': {
                  position: 'absolute',
                  top: `calc(50% - ${isMobile ? '40' : '90'}px)`,
                  right: `calc(50% - ${isMobile ? '40' : '90'}px)`,
                },
              }}>
                <Mail className='mail-icon' color='#FFF' size={isMobile ? '80' : '180'} />
                <Box display='flex' padding='4px' borderRadius='50%' position='absolute' bottom='60px' right='80px' sx={{
                   backgroundColor:'secondary.main',
                }}>
                  <PlusCircle size={isMobile ? '30' : '70'} color='#FFF' />
                </Box>
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>
      <Box sx={{
         backgroundColor: 'text.disabled',
      }}>
        <Box borderRadius='0 0 100px 0' sx={{ backgroundColor: 'primary.main', height: { xs: '60px', md: '110px' } }}/>
      </Box>
      <Box sx={{
         backgroundColor:'primary.main',
      }}>
        <Box borderRadius='100px 0' sx={{ backgroundColor: 'text.disabled', padding: { xs: '60px 32px', md: '110px' } }}>
          <Container>
            <Box display='flex' alignItems='center' sx={{
              flexDirection: {
                xs: 'column',
                md: 'row',
              },
              mt: {
                xs: '32px',
                md: '80px',
              },
              gap: {
                xs: '32px',
                md: '120px',
              },
            }}>
              <Box position='relative' borderRadius='100px 0' sx={{
                backgroundColor: 'secondary.main',
                height: {
                  xs: '100px',
                  md: '280px',
                },
                width: {
                  xs: '200px',
                  md: '700px',
                },
                '.layout-icon': {
                  position: 'absolute',
                  top: `calc(50% - ${isMobile ? '40' : '90'}px)`,
                  right: `calc(50% - ${isMobile ? '40' : '90'}px)`,
                },
              }}>
                <Layout className='layout-icon' color='#FFF' size={isMobile ? '80' : '180'} />
                <Box display='flex' padding='2px' borderRadius='50%' position='absolute' bottom='40px' right='50px' sx={{
                   backgroundColor: 'secondary.main',
                }}>
                  <Search size={isMobile ? '30' : '100'} color='#FFF' />
                </Box>
              </Box>
              <Box sx={{ maxWidth: { xs: '100%', md: '60%' } }}>
                <Typography variant='h3' sx={{ fontSize: { xs: '24px', md: '48px' } }}>The search is on us</Typography>
                <Typography variant='h5' mt='20px' sx={{ fontSize: { xs: '16px', md: '24px' } }}>
                  We sift through extensive sustainable product catalogues found online and find the perfect sustainable alternatives for you. 
                </Typography>
              </Box>
            </Box>
          </Container>
        </Box>
      </Box>
      <Box sx={{
        backgroundColor: 'text.disabled',
      }}>
        <Box borderRadius='100px 0 0' sx={{  backgroundColor: 'primary.main', padding: { xs: '60px 32px 0', md: '110px 0 0' } }}>
          <Container>
            <Box display='flex' alignItems='center' sx={{
              flexDirection: {
                xs: 'column',
                md: 'row',
              },
              mt: {
                xs: '32px',
                md: '80px',
              },
              gap: {
                xs: '32px',
                md: '120px',
              },
            }}>
              <Box sx={{ maxWidth: { xs: '100%', md: '60%' } }}>
                <Typography variant='h3' sx={{ fontSize: { xs: '24px', md: '48px' } }}>Green Cart set to go</Typography>
                <Typography variant='h5' mt='20px' sx={{ fontSize: { xs: '16px', md: '24px' } }}>
                We then generate a cart full of alternatives for your staples. We’ll let you know once the Green Cart is ready. All you have to do is just hit the order button. Yes, it’s that easy. 
                </Typography>
              </Box>
              <Box position='relative' height='280px' width='700px' borderRadius='100px 0' sx={{
                backgroundColor: 'secondary.main',
                height: {
                  xs: '100px',
                  md: '280px',
                },
                width: {
                  xs: '200px',
                  md: '700px',
                },
                '.cart-icon': {
                  position: 'absolute',
                  top: `calc(50% - ${isMobile ? '40' : '90'}px)`,
                  right: `calc(50% - ${isMobile ? '40' : '90'}px)`,
                },
              }}>
                <ShoppingCart className='cart-icon' color='#FFF' size={isMobile ? '80px' : '180'} />
                <Box display='flex' borderRadius='50%' position='absolute' sx={{
                  bottom: {
                    xs: 'calc(50% - 12px)',
                    md: 'calc(50% - 30px)',
                  },
                  right: {
                    xs: 'calc(50% - 25px)',
                    md: 'calc(50% - 55px)',
                  }
                }}>
                  <Check size={isMobile ? '30px' :'70'} color='#FFF' />
                </Box>
              </Box>
            </Box>
          </Container>
        </Box>
      </Box>
      <Box sx={{
        backgroundColor: 'background.default',
      }}>
        <Box borderRadius='0 0 100px 0' sx={{  backgroundColor: 'primary.main', height: { xs: '80px', md: '110px' } }} />
      </Box>
      <Footer handleLoginClick={handleLoginClick} />
    </Box>
  )
}

export default Landing