import React, { useCallback, useEffect, useState } from 'react'

import { Buffer } from 'buffer'

import { Box, Button, Typography } from '@mui/material'

declare global {
  interface Window {
    gapi: any
    google: any
  }
}

const CLIENT_ID = '859760288234-0rfnk52tocpi75aigdiqef25v40a61ra.apps.googleusercontent.com'
const API_KEY = 'AIzaSyD2fNnuT5s6Qay3gVB3P8ebJJSFA0KihEY'
const DISCOVERY_DOC = 'https://www.googleapis.com/discovery/v1/apis/gmail/v1/rest';
const SCOPES = 'https://www.googleapis.com/auth/gmail.readonly';

const marketplaces = [
  {
    name: 'Walmart',
    filter: {
      from: 'help@walmart.com',
      subject: 'Order received',
    }
  }
]

const ConnectGmail = ({ userId }) => {
  const [gapiInitiated, setGapiInitiated] = useState(false)
  const [gisInitiated, setGisInitiated] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [messages, setMessages] = useState([])
  const [tokenClient, setTokenClient] = useState<any>()
  const [shouldShowAuthButtons, setShouldShowAuthButtons] = useState(true)

  const intializeGapiClient = async() => {
    await window.gapi.client.init({
      apiKey: API_KEY,
      discoveryDocs: [DISCOVERY_DOC],
    });

    setGapiInitiated(true)
  }

  const gisLoaded = () => {
    const tempTokenClient = window.google.accounts.oauth2.initTokenClient({
      client_id: CLIENT_ID,
      scope: SCOPES,
      callback: '', // defined later
    });

    tempTokenClient.callback = async (resp) => {
      if (resp.error !== undefined) {
        throw (resp);
      }
      
      setShouldShowAuthButtons(false)

      marketplaces.forEach(marketplace => {
        listMessages(marketplace);
      })
    };

    setTokenClient(tempTokenClient)
    setGisInitiated(true)
  }

  const getMessage = async (messageId, marketplaceName) => {
    let response;

      try {
        response = await window.gapi.client.gmail.users.messages.get({
          'id': messageId,
          'userId': 'me',
          'q': 'format:full'
        });
      } catch (err) {
        setErrorMessage(err.message)
        return;
      }

      if (!response.result) {
        console.log(`Message not found. Id ${messageId}`)
        return;
      }

      const sender = response.result.payload.headers.find(({ name }) => name === 'From').value
      const subject = response.result.payload.headers.find(({ name }) => name === 'Subject').value
      const date = response.result.payload.headers.find(({ name }) => name === 'Date').value

      const message = {
        id: response.result.id,
        sender,
        subject,
        date,
        marketplace: marketplaceName,
      };
      const messageBodyEncoded = response.result.payload.body.data
      const messageBody = Buffer.from(messageBodyEncoded, 'base64').toString()

      //console.log('messageBody', messageBody)
  }

  const listMessages = async (marketplace) => {
    let response;

    try {
      response = await window.gapi.client.gmail.users.messages.list({
        'userId': 'me',
        'q': `from:${marketplace.filter.from} subject:${marketplace.filter.subject}`
      });
    } catch (err) {
      setErrorMessage(err.message)
      return;
    }

    response.result.messages.forEach(({ id }) => getMessage(id, marketplace.name))
  }

  const handleAuthClick = useCallback(() => {
    if (!tokenClient) return

    if (window.gapi.client.getToken() === null) {
      // Prompt the user to select a Google Account and ask for consent to share their data
      // when establishing a new session.
      tokenClient.requestAccessToken({prompt: 'consent'});
    } else {
      // Skip display of account chooser and consent dialog for an existing session.
      tokenClient.requestAccessToken({prompt: ''});
    }
  }, [tokenClient])

  const loadScript = (src, onLoad) => {
    const script = document.createElement("script");
    script.src = src;
    script.async = true;
    script.onload = onLoad;
  
    document.body.appendChild(script);
  }

  useEffect(() => {
    const gapiLoaded = () => {
      window.gapi.load('client', intializeGapiClient);
    }

    loadScript("https://apis.google.com/js/api.js", gapiLoaded)
    loadScript('https://accounts.google.com/gsi/client', gisLoaded)
  }, [])

  return (
    <Box>
      {shouldShowAuthButtons && (
        <Button onClick={handleAuthClick} disabled={true || !(gapiInitiated && gisInitiated)} variant='contained' color='secondary'>
          <Box display='flex' flexDirection='column'>
            <Typography sx={{ fontSize: '24px', textTransform: 'none' }}>Connect your Gmail inbox</Typography>
          </Box>
        </Button>
      )}
      {(!shouldShowAuthButtons && !messages?.length) && (
        <Typography>Processing messages.</Typography>
      )}
    </Box>
  )
}

export default ConnectGmail