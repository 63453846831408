import React, { useEffect, useState } from 'react'

import { useNavigate } from 'react-router-dom'

import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import Navbar from './components/Navbar';
import WhoWeAre from './views/WhoWeAre';
import Landing from './views/Landing'
import ContentManagement from './views/ContentManagement';
import Dashboard from './views/Dashboard';
import { GreenUpUser, PairedItem } from './types'

const App = ({ auth, getFromDatabase, signInWithPopup, signOutUser, writeToDatabase, getFromDatabaseQueryAll, getFromDatabaseQueryWhere }) => {
  const match = window.location.pathname.replace('/', '')

  const navigate = useNavigate()

  const [isLoginPending, setIsLoginPending] = useState<boolean>(false)
  const [user, setUser] = useState<GreenUpUser>()
  const [navColor, setNavColor] = useState('transparent')
  const [shoppingList, setShoppingList] = useState<PairedItem[]>()
  const [isFetchingShoppingList, setIsFetchingShoppingList] =  useState<boolean>(false)
  const [prevItemCount, setPrevItemCount] = useState<number | undefined>()
  const [existingCategories, setExistingCategories] = useState<{ slug: string }[] | undefined>()

  const greenupTheme = createTheme({
    palette: {
      background: {
        default: '#001917',
        
      },
      mode: 'light',
      primary: {
        main: '#FFF',
      },
      secondary: {
        main: '#014C47',
      },
      text: {
        secondary: '#EEFF2F',
        disabled: '#E6EDED',
      },
    },
    typography: {
      fontFamily: 'Clash Display Regular',
    }
  });

  const handleLoginClick = () => {
    setIsLoginPending(true)
    signInWithPopup((data) => {
      const splitName = data.user.displayName.split(' ')
      const newUser = {
        accessToken: data.user.accessToken,
        createdAt: data.user.metadata.createdAt,
        name: {
          first: splitName[0],
        },
        email: data.user.email,
        lastLogin: data.user.metadata.lastLoginAt,
        profileImgUrl: data.user.photoURL,
        uid: data.user.uid,
      }

      writeToDatabase('users', data.user.email, newUser)

      setUser(newUser)
      setIsLoginPending(false)

      navigate('/dashboard', { replace: true })
    })
  }

  const fetchShoppingList = (userData) => {
    setIsFetchingShoppingList(true)

    getFromDatabase('shoppingLists', userData.uid, (listResult) => {
      getFromDatabase('trashbags', userData.uid, (bagResult) => {
        const listCount = listResult?.shoppingList?.length || 0
        const bagCount = bagResult?.trashbag?.length || 0
        const count = listCount + bagCount

        setPrevItemCount(count)
        setShoppingList(listResult?.shoppingList)
        setIsFetchingShoppingList(false)
      })
    })
  }

  useEffect(() => {
    auth.onAuthStateChanged(currentUser => {
      if (currentUser && currentUser.email !== user?.email) setIsLoginPending(true)
      if (auth.currentUser && !user) {
        getFromDatabase('users', currentUser.email, (userData) => {
          setUser(userData)
          setIsLoginPending(false)
        })
      }
    })
  }, [])

  useEffect(() => {
    if (!user) return

    if (!isFetchingShoppingList) fetchShoppingList(user)

    getFromDatabaseQueryAll('productByCategory', (results) => {
      setExistingCategories(results || [])
    })
  }, [user])

  // useEffect(() => {
  //   if (user && !shoppingList?.length && !isFetchingShoppingList && match !== 'home')  {
  //     setIsFetchingShoppingList(true)

  //     getFromDatabase('shoppingLists', user.uid, (results) => {
  //       setShoppingList(results.shoppingList || [])
  //       setIsFetchingShoppingList(false)

  //       if (results.shoppingList && match !== 'cms') navigate('/home', { replace: true })
  //     })
  //   }
  // }, [user, match])

  // useEffect(() => {
  //   if (user && shoppingList?.length && match === 'home')  {
  //     navigate('/dashboard', { replace: true })
  //   }
  // }, [user, shoppingList, match])

  return (
    <ThemeProvider theme={greenupTheme}>
      <CssBaseline />
      <Navbar
        handleLoginClick={handleLoginClick}
        isLoginPending={isLoginPending}
        user={user}
        setUser={setUser}
        signOutUser={signOutUser}
        navColor={navColor}
        isDashboard={match === 'dashboard'}
      />
      {{
        'about-us': <WhoWeAre handleLoginClick={handleLoginClick} navColor={navColor} setNavColor={setNavColor} />,
        dashboard: (
          <Dashboard
            handleLoginClick={handleLoginClick}
            user={user}
            shoppingList={shoppingList}
            prevItemCount={prevItemCount}
            existingCategories={existingCategories}
            refetchShoppingList={fetchShoppingList}
            isLoadingShoppingList={isFetchingShoppingList}
          />
        ),
        cms: user ? <ContentManagement getFromDatabaseQueryAll={getFromDatabaseQueryAll} user={user} writeToDatabase={writeToDatabase} getFromDatabase={getFromDatabase} getFromDatabaseQueryWhere={getFromDatabaseQueryWhere} /> : null,
        default: <Landing user={user} handleLoginClick={handleLoginClick} navColor={navColor} setNavColor={setNavColor} />,
      }[match || 'default']}
    </ThemeProvider>
  );
}

export default App;
