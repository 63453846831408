import React, { useEffect, useState } from 'react'

import { Box, Button, Container, Input, Typography } from '@mui/material'

import SuggestedAlternativeProduct from '../components/SuggestedAlternativeProduct'
import { GreenItemProps, GreenUpUser } from '../types'

import { writeToDatabase, getFromDatabase, getFromDatabaseQueryWhere } from '../index';

// Note: A "Trashbag" is a user's list of past purchases that hasn't been sorted
// Each user has one trashbag and one shoppingLists

interface ContentManagementProps {
  user: GreenUpUser,
  getFromDatabase: (collectionName: string, itemName: string, callback: (result: { email: string }) => void) => void
  getFromDatabaseQueryWhere: (collectionName: string, whereArr: string[], callback: (result: { slug: string }[]) => void) => void
  getFromDatabaseQueryAll: (collectionName: string) => {}
  writeToDatabase: (collectionName: string, itemName: string, data: any) => {}
}

interface AlternativeFormProps {
  name: string
  description: string
  manufacturer: string
  thumbnail: string
  price: string
}

const ContentManagement = ({ user }: ContentManagementProps) => {
  const [isAuthorized, setIsAuthorized] = useState<boolean | undefined>()
  const [isFetchingEmptyCategories, setIsFetchingEmptyCategories] = useState(false)
  const [emptyCategories, setEmptyCategories] = useState<string[] | undefined>()
  const [alternateForm, setAlternateForm] = useState<AlternativeFormProps | {}>({})

  const isGreenItem = (item: any): item is GreenItemProps => {
    return (item.name && item.certifiedBy && item.description && item.imgSrc && item.price && item.seller && item.quantity);
  }

  const handleSubmitDefaultProduct = () => {
    if (!emptyCategories) return null

    writeToDatabase('productByCategory', emptyCategories[0], {
      product: alternateForm,
    }, () => {
      skipToNextProduct()
    })
  }

  const skipToNextProduct = () => {
    setAlternateForm({})
    setEmptyCategories(prevEmptyCategories => {
      if (!prevEmptyCategories) return undefined

      const newEmptyCategories = [...prevEmptyCategories]

      newEmptyCategories.shift()

      return newEmptyCategories
    })
  }

  const updateAlternate = (fieldName: 'name' | 'description' | 'manufacturer' | 'thumbnail' | 'price', value: string) => {
    setAlternateForm(prevAlternateForm => {
      const newForm = { ...prevAlternateForm }

      newForm[fieldName] = value

      return newForm
    })
  }

  // const fetchAllShoppingLists = (callback) => getFromDatabaseQueryAll('shoppingLists', (listRes) => {
  //   callback(listRes)
  // })

  // const addMissingCategories = (lists, existingCategories) => {
  //   lists.forEach(({ shoppingList }) => {
  //     shoppingList.forEach(item => {
  //       if (!existingCategories?.some(category => category.slug === item.category)) {
  //         console.log('productByCategory', item)
  //         writeToDatabase('productByCategory', item.replacedProduct.category, {
  //           slug: item.replacedProduct.category,
  //           product: 'none',
  //         })
  //       }
  //     })
  //   })
  // }

  useEffect(() => {
    if (!user) return

    getFromDatabase('admins', user.email, (result) => {
      if (result?.email === user.email) setIsAuthorized(true)
      else setIsAuthorized(false)
    })
  }, [])

  useEffect(() => {
    if (!isAuthorized || isFetchingEmptyCategories || emptyCategories) return

    setIsFetchingEmptyCategories(true)
    getFromDatabaseQueryWhere('productByCategory', ["product", "==", "none"], results => {
      setIsFetchingEmptyCategories(false)
      if (!results) return setEmptyCategories([])

      setEmptyCategories(results.map(item => item.slug))
    })
    
  }, [isAuthorized, isFetchingEmptyCategories, emptyCategories])

  // useEffect(() => {
  //   getFromDatabaseQueryAll('productByCategory', (results) => {
  //     const existingCategories = results || []

  //     fetchAllShoppingLists((lists) => {
  //       addMissingCategories(lists, existingCategories)
  //     })
  //   })
  // }, [])

  if (!isAuthorized) return <Typography>Checking auth</Typography>

  return (
    <Box paddingTop='80px'>
      <Container>
        <Box sx={{ backgroundColor: 'primary.main' }} padding='32px' borderRadius='10px'>
          <Typography variant='h2'>Admin Panel</Typography>
          <Box mt='32px'>
            <Typography variant='h5'>Match a product to a category</Typography>

            {Boolean(emptyCategories?.length) && (
              <Box mt='32px'>
                {!emptyCategories && (
                  <Typography variant='h5'>All categories have been matched</Typography>
                )}
                {emptyCategories && (
                  <Box>
                    <Box display='flex' alignItems='center' mb='24px' gap='16px'>
                      <Typography variant='h5'>{emptyCategories[0]}</Typography>
                      <Button variant='contained' onClick={skipToNextProduct}><Typography color='secondary.main'>Skip</Typography></Button>
                    </Box>

                    <Box display='flex' gap='32px'>
                      <Box display='inline-flex' flexDirection='column' gap='16px' key={emptyCategories[0]}>
                        <Box>
                          <Typography>Name</Typography><Input onChange={(event) => updateAlternate('name', event.target.value)} />
                        </Box>
                        <Box>
                          <Typography>Description</Typography><Input onChange={(event) => updateAlternate('description', event.target.value)} />
                        </Box>
                        <Box>
                          <Typography>Manufacturer</Typography><Input onChange={(event) => updateAlternate('manufacturer', event.target.value)} />
                        </Box>
                        <Box>
                          <Typography>Image URL</Typography><Input onChange={(event) => updateAlternate('thumbnail', event.target.value)} />
                        </Box>
                        <Box>
                          <Typography>Price</Typography><Input onChange={(event) => updateAlternate('price', event.target.value)} />
                        </Box>

                        <Button variant='contained' onClick={handleSubmitDefaultProduct}><Typography color='secondary.main'>Submit</Typography></Button>
                      </Box>

                      {isGreenItem(alternateForm) && (
                        <Box minWidth='600px'>
                          <SuggestedAlternativeProduct item={alternateForm} />
                        </Box>
                      )}
                    </Box>
                  </Box>
                )}
              </Box>
            )}
          </Box>
        </Box>
      </Container>
    </Box>
  )
}

export default ContentManagement