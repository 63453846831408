import React from 'react'

import { Box, Button, Container, Typography } from '@mui/material'

import Socials from './Socials'

const Footer = ({ handleLoginClick }) => {
  return (
    <Box sx={{
      backgroundColor: 'primary.main',
    }}>
        <Box borderRadius='100px 0 0 0' sx={{
          backgroundColor: 'background.default',
          padding: {
            xs: '80px 0 40px',
            md: '110px 0',
          },
        }}>
          <Container>
            <Box display='flex' flexDirection='column' alignItems='center'>
              <Typography textAlign='center' color='primary.main' variant='h1' sx={{fontFamily: 'Clash Display Bold', fontSize: { xs: '32px', md: '96px' } }}>Loving Earth couldn't be made easier</Typography>
              <Button onClick={handleLoginClick} sx={{
                backgroundColor: 'text.secondary',
                borderRadius: '100px 0',
                height: '80px',
                mt: {
                  xs: '32px',
                  md: '100px',
                },
                width: '300px',
                '&:hover': {
                  backgroundColor: 'secondary.main',
                  opacity: '80%',
                }
              }}>
                <Typography variant='button' fontSize='32px' textTransform='none' color='background.default' sx={{ fontFamily: 'Clash Display Bold' }}>Give love</Typography>
              </Button>
              <Socials />
            </Box>
          </Container>
        </Box>
      </Box>
  )
}

export default Footer