import React from 'react';

import { initializeApp } from "firebase/app";
import { getAuth, signInWithPopup, GoogleAuthProvider, signOut } from "firebase/auth";
import { addDoc, collection, doc, getDoc, getDocs, getFirestore, limit, updateDoc, setDoc, query, deleteDoc, where } from "firebase/firestore"; 

import ReactDOM from 'react-dom/client';
import {
  BrowserRouter,
  Routes,
  Route,
} from 'react-router-dom';

import App from './App';

import reportWebVitals from './reportWebVitals';

import './index.css'

const firebaseConfig = {

  apiKey: "AIzaSyBCEPHfGfoC4GvJ2Qe6zhBZL19OlEeAHTg",

  authDomain: "greenup-a6a09.firebaseapp.com",

  projectId: "greenup-a6a09",

  storageBucket: "greenup-a6a09.appspot.com",

  messagingSenderId: "880718304341",

  appId: "1:880718304341:web:614c1f69ee253dc98b30dc",

  measurementId: "G-40WNG1HCE3"

};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app)
const provider = new GoogleAuthProvider();
const auth = getAuth();

const signIn = (callback) => {
  signInWithPopup(auth, provider).then(result => {
    const credential = GoogleAuthProvider.credentialFromResult(result)

    if (!credential) return

    const token = credential.accessToken;
    const user = result.user;

    callback({
      token,
      user,
    })

  }).catch(error => console.log('error on sign in', error))
}

const deleteFromDatabase = async (collectionName, itemName) => {
  return deleteDoc(doc(db, collectionName, itemName));
}

const updateDatabase = async (collectionName, itemName, data) => {
  const docRef = doc(db, collectionName, itemName)

  return updateDoc(docRef, data)
}

export const writeToDatabase = async (collectionName, itemName, data, callback) => {
  if (itemName) return setDoc(doc(db, collectionName, itemName), data, { merge: true }).then(callback)
  else return addDoc(collection(db, collectionName), data).then(callback)
}

export const getFromDatabaseQueryWhere = async (collectionName, whereArgs, callback) => {
  const collectionRef = collection(db, collectionName);
  const q = query(collectionRef, where(whereArgs[0], whereArgs[1], whereArgs[2]));
  const querySnapshot = await getDocs(q);
  const results: any[] = []

  querySnapshot.forEach(docSnap => {
    results.push(docSnap.data())
  })

  if (callback) callback(results)
}

export const getFromDatabaseQueryAll = async (collectionName, callback) => {
  const querySnapshot = await getDocs(collection(db, collectionName));
  const results: any[] = []

  querySnapshot.forEach((doc) => {
    const result = doc.data()

    results.push(result)
  });

  if (callback) callback(results)
}

const getFromDatabaseQueryLimit = async (collectionName, limitAmount, callback) => {
  const urlsRef = collection(db, collectionName);
  const q = query(urlsRef, limit(limitAmount));

  const querySnapshot = await getDocs(q);

  querySnapshot.forEach(docSnap => {
    if (callback) callback(docSnap.data())
  })
}

export const getFromDatabase = async (collectionName, itemName, callback) => {
  const docRef = doc(db, collectionName, itemName)
  const docSnap = await getDoc(docRef)

  if (docSnap.exists()) {
    const userData = docSnap.data()

    if (callback) callback(userData)
  }
  else callback(false)
}

const signOutUser = (callback) => {
  signOut(auth).then(callback)
}

const docRoot = document.getElementById('root')

const root = docRoot && ReactDOM.createRoot(docRoot);
const BaseElement = (
  <App
    getFromDatabase={getFromDatabase}
    signInWithPopup={signIn}
    writeToDatabase={writeToDatabase}
    auth={auth}
    signOutUser={signOutUser}
    getFromDatabaseQueryAll={getFromDatabaseQueryAll}
    getFromDatabaseQueryWhere={getFromDatabaseQueryWhere}
  />
)

root?.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/about-us" element={BaseElement} />
        <Route path="/dashboard" element={BaseElement} />
        <Route path="/cms" element={BaseElement} />
        <Route path="/" element={BaseElement} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
