import React from 'react'

import { Trash2 } from 'react-feather'

import { Box, Button, Typography } from '@mui/material'

import { GreenItemProps } from '../types'

interface SuggestedAlternativeProductProps {
  item: GreenItemProps
}

const SuggestedAlternativeProduct = ({
  item,
}: SuggestedAlternativeProductProps) => {
  if (!item) return null

  return (
    <Box marginTop='-12px' boxShadow='0px 0px 16px 4px rgba(0, 0, 0, 0.08)' borderRadius='16px' padding='16px 32px' sx={{
      backgroundColor: 'primary.main',
    }}>
      <Box display='flex' justifyContent='space-between'>
        <Box display='flex' gap='24px'>
          <Box>
            {item.certifiedBy && <Box>Placeholder</Box>}
            <img width='120px' src={item.imgSrc} alt={item.name} />
          </Box>
          <Box display='flex' flexDirection='column' justifyContent='space-between'>
            <Typography variant='h5'>{item.name}</Typography>
            <Typography>{item.description}</Typography>
            <Typography>{item.seller}</Typography>
            <Typography>QTY: {item.quantity}</Typography>
          </Box>
        </Box>
        <Box display='flex' flexDirection='column' justifyContent='space-between' alignItems='flex-end'>
          <Typography variant='h5' sx={{ fontWeight: 600 }}>{`$${item.price}`}</Typography>
          <Button><Trash2 color='black' /></Button>
        </Box>
      </Box>
    </Box>
  )
}

export default SuggestedAlternativeProduct