import React, { useEffect, useState } from 'react'

import { Box, Button, Typography, useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'

import AddShoppingData from '../components/AddShoppingData'
import SuggestedAlternativeProduct from '../components/SuggestedAlternativeProduct'
import { PairedItem } from '../types'

const Dashboard = ({ shoppingList, user, handleLoginClick, prevItemCount, existingCategories, isLoadingShoppingList, refetchShoppingList }) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  
  const [pairsByCategory, setPairsByCategory] = useState<{ [key: string]: PairedItem[] }>()

  useEffect(() => {
    if (!pairsByCategory && shoppingList?.length) {
      const tempPairsByCategory = {}

      shoppingList.forEach(pair => {
        const category = pair.replacedProduct.category.split('_').join(' ').toLowerCase()
        const newPair = {
          category,
          oldItem: {
            name: pair.replacedProduct.name,
          },
          greenItem: {
            certifiedBy: undefined,
            description: pair.alternative.descr,
            imgSrc: pair.alternative.thumbnail,
            name: pair.alternative.name,
            seller: pair.alternative.manufacturer,
            quantity: 1,
            price: pair.alternative.price ? (+pair.alternative.price).toFixed(2) : '',
          }
        }

        if (tempPairsByCategory[category]) {
          if (tempPairsByCategory[category].some(pair => pair.greenItem.name === newPair.greenItem.name)) {
            const existsIndex =  tempPairsByCategory[category].findIndex(item => item.greenItem.name === newPair.greenItem.name)

            tempPairsByCategory[category][existsIndex].greenItem.quantity++
          }
          else tempPairsByCategory[category].push(newPair)
        }
        else tempPairsByCategory[category] = [newPair]
      })

      setPairsByCategory(tempPairsByCategory)
    }
  }, [shoppingList])

  if (!user) return null

  return (
    <Box height='100vh' overflow='hidden' sx={{
      backgroundColor: 'primary.main',
    }}>
      <Box display='flex' width='100%' maxWidth='100vw' overflow='hidden' maxHeight='100%'>
        <Box paddingTop={isMobile ? '80px' : '128px'} width={`calc(100% - ${isMobile ? 8 : 160}px)`} marginLeft={isMobile ? '8px' : '160px'} height='100%' maxHeight='100%' sx={{
           backgroundColor: 'primary.main',
        }}>
          <Box padding={isMobile ? '32px 16px' : '80px 60px'} borderRadius={isMobile ? '0' : '100px 0 0 0'} width='100%' height='100%' sx={{
             backgroundColor: 'text.disabled',
          }}>
            {isLoadingShoppingList && 'Loading...'}
            {!isLoadingShoppingList && (
              <>
                {shoppingList?.length
                  ? (
                    <Box height='100%' display='flex' gap='48px'>
                      <Box padding='32px' height='100%' width={isMobile ? '100%' : '60%'} borderRadius='48px' sx={{
                        backgroundColor: 'primary.main',
                      }}>
                        <Box>
                          <Box paddingBottom='24px' marginBottom='24px' borderBottom='1px solid' sx={{ borderColor: 'text.disabled' }}>
                            <Typography variant='h4'>Green Cart</Typography>
                            <Typography>Based on your recurring orders</Typography>
                          </Box>
                          <Box paddingBottom='32px' maxHeight='50vh' overflow='auto'>
                            {pairsByCategory && Object.keys(pairsByCategory).map(category => (
                              <Box key={category}>
                                <Box display='flex' justifyContent='space-between' alignItems='center'>
                                  <Typography variant='h4' textTransform='capitalize' marginY='24px'>{category}</Typography>
                                  <Box>{category.length}</Box>
                                </Box>
                                {pairsByCategory[category].map((pair, i) => (
                                  <Box key={`${category}_${i}`}>
                                    <Box minHeight='100px' borderRadius='16px 16px 0 0' padding='16px 32px' sx={{
                                      backgroundColor: '#F3F3F3',
                                    }}>
                                      <Box>
                                        <Box display='flex'>
                                          <Typography textTransform='uppercase' color='#039990'>Greener option</Typography>
                                          <Typography marginLeft='4px'>for</Typography>
                                        </Box>
                                        <Typography variant='h5' sx={{
                                          whiteSpace: 'nowrap',
                                          overflow: 'hidden',
                                          textOverflow: 'ellipsis',
                                        }}>
                                          {pair.oldItem.name}
                                        </Typography>
                                      </Box>
                                    </Box>
                                    <SuggestedAlternativeProduct item={pair.greenItem} />
                                  </Box>
                                ))}
                              </Box>
                            ))}
                          </Box>
                        </Box>
                        <Box padding='32px' borderRadius='0 0 48px 48px' height='150px' boxShadow='0px 0px 16px 4px rgba(0, 0, 0, 0.08)' margin='0 -32px -32px' sx={{
                          backgroundColor: 'primary.main',
                          zIndex: '2'
                        }}>
                          {pairsByCategory && (
                            <Box display='flex' justifyContent='space-between'>
                              <Typography variant='h3' sx={{ fontFamily: 'Clash Display Semibold' }}>$</Typography>
                              <Button href={user ? '/home' : undefined} onClick={!user ? handleLoginClick : () => {}} sx={{
                                backgroundColor: 'secondary.main',
                                borderRadius: '100px 0',
                                height: {
                                  xs: '48px',
                                  md: '80px',
                                },
                                width: '330px',
                                '&:hover': {
                                  backgroundColor: 'secondary.main',
                                  opacity: '80%',
                                }
                              }}>
                                <Typography variant='button' textTransform='capitalize' color='text.secondary' sx={{ fontSize: { xs: '24px', md: '32px' } }}>
                                    Buy items
                                </Typography>
                              </Button>
                            </Box>
                          )}
                        </Box>
                      </Box>
                    </Box>
                  )
                  : (
                    <AddShoppingData refetchShoppingList={refetchShoppingList} prevItemCount={prevItemCount} existingCategories={existingCategories} user={user} />
                  )
                }
              </>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default Dashboard

// `$${Object.keys(pairsByCategory).reduce((a, b) => {
//   console.log(pairsByCategory[b])
//   const p = pairsByCategory[b].reduce((c, d) => {
//     console.log('c,d', c, d, pairsByCategory[b])
//     return (typeof c === 'number' ? a : 0) + +d.greenItem.price
//   })

//   return (typeof a === 'number' ? a : 0) + +p
// })}`