import React, { useState } from 'react'

import { Box, Button, Divider, Link, Typography } from '@mui/material'

import ConnectGmail from '../components/ConnectGmail'
import UploadCSV from '../components/UploadCSV'

const AddShoppingData = ({ user, prevItemCount, existingCategories, refetchShoppingList }) => {
  const [shouldShowManualUploadInstructions, setShouldShowManualUploadInstructions] = useState<boolean>(false)

  return (
    <Box height='100%' alignItems='center' justifyContent='center' display='flex' flexDirection='column'>
      <Typography variant='h3'>You're unlocking sustainable shopping on autopilot</Typography>
      <Typography margin='80px 0 24px' variant='h4' width='100%' maxWidth='1200px'>If you shop with:</Typography>

      <Box display='flex' justifyContent='space-between' width='100%' maxWidth='1200px' padding='80px' borderRadius='100px 0' boxShadow='0px 5px 16px 8px rgba(9, 48, 41, 0.3)' height='400px' sx={{ backgroundColor: 'primary.main' }}>
        <Box maxWidth='50%' display='flex' flexDirection='column' justifyContent='space-between' height='100%'>
          <Typography variant='h5' sx={{ fontFamily: 'Clash Display Semibold' }}>Walmart, Target, Kohl's:</Typography>
          <Typography textAlign='left' sx={{ fontSize: '24px' }}>We'll scan your email for purchases and automatically keep your sustainable shopping trends up to date</Typography>
          <ConnectGmail userId={user.uid}  />
        </Box>

        <Divider orientation='vertical' />

        <Box maxWidth='50%' display='flex' flexDirection='column' justifyContent='space-between' height='100%'>
          <Typography variant='h5' sx={{ fontFamily: 'Clash Display Semibold' }}>Amazon:</Typography>
          <Box>
            <Typography textAlign='left' sx={{ fontSize: '24px' }}>Download your purchase history from </Typography>
            <Link href='https://www.amazon.com/b2b/reports' target='_blank'>
              <Typography textAlign='center' sx={{ fontSize: '24px' }} color='secondary.main'>amazon.com/b2b/reports</Typography>
            </Link>
            <Typography textAlign='left' sx={{ fontSize: '24px' }}>and upload the generated CSV file below</Typography>
          </Box>
          <UploadCSV refetchShoppingList={refetchShoppingList} existingCategories={existingCategories} prevItemCount={prevItemCount} user={user} />
        </Box>
      </Box>
      <Box mt='24px' width='100%' maxWidth='1200px'>
        <Button variant='text' onClick={() => setShouldShowManualUploadInstructions(true)}><Typography textAlign='left' color='secondary.main' sx={{ fontSize: '24px', textTransform: 'none' }}>I prefer to manually send indivual emails</Typography></Button>
      </Box>
      {shouldShowManualUploadInstructions && (
        <Box mt='12px' width='100%' maxWidth='1200px'>
          <Typography color='secondary.main' sx={{ fontSize: '24px' }}>You can forward your itemized Walmart, Target, and Kohl's email order receipts to</Typography>
          <Typography color='secondary.main' sx={{ fontFamily: 'Clash Display Semibold', fontSize: '24px' }}>manual-submissions@shopgreenup.com</Typography>
        </Box>
      )}
    </Box>
  )
}

export default AddShoppingData