import React, { useState } from 'react'

import { Button, Typography } from '@mui/material'
import { arrayUnion } from 'firebase/firestore';

import { getFromDatabase, writeToDatabase } from '../index'

const alternativesEndpoint = 'https://us-central1-greenup-357704.cloudfunctions.net/getProducts'

const UploadCSV = ({ user, prevItemCount, existingCategories, refetchShoppingList }) => {
  const [isProcessingData, setIsProcessingData] = useState(false)

  const isHTMLInputElement = (el: any): el is HTMLInputElement => {
    return (el.files);
  }

  const fetchSustainableAlternatives = (keywords) => {
    const raw = { keywords };
    const body = JSON.stringify(raw);
    let headers = new Headers();

    headers.append("Accept", "*/*");
    headers.append("Accept-Language", "en-US,en;q=0.5");
    headers.append("Content-Type", "text/plain");

    return fetch(alternativesEndpoint,
      {
        body,
        headers,
        method: 'POST',
        redirect: 'follow'
      })
      .then(res => {
        const text = res.text();
        return text;
      })
      .catch(e => console.log(`Error fetching results: ${e}`));
  }

  const checkIfAllItemsWereProcessed = async (itemCount) => {
    if (typeof prevItemCount !== 'number') return

    getFromDatabase('trashbags', user.uid, (bagRes) => {
      getFromDatabase('shoppingLists', user.uid, (listRes) => {
        const listCount = listRes?.shoppingList?.length || 0
        const bagCount = bagRes?.trashbag?.length || 0

        if (bagCount + listCount >= prevItemCount + (itemCount || 0)) {
          setIsProcessingData(false)
          refetchShoppingList(user)
        }
      })
    })
  }

  // Add the matched pair to user's shopping cart
  const handleSelectedAlternative = (replacedProduct, alternative, itemCount) => {
    const matchedPair = {
      alternative,
      replacedProduct,
    }

    writeToDatabase('shoppingLists', user.uid, {
      shoppingList: arrayUnion(matchedPair)
    }, () => checkIfAllItemsWereProcessed(itemCount))
  }

  const handleUnmatchedProduct = (unmatchedProduct, itemCount) => {
    const keywords = unmatchedProduct.category.split('_').join(' ')

    getFromDatabase('productByCategory', unmatchedProduct.category, (result) => {
      if (result && result.product !== 'none') {
        handleSelectedAlternative(unmatchedProduct, result.product, itemCount)
      }
      else {
        fetchSustainableAlternatives(keywords).then((res) => {
          if (typeof res === 'string') {
            const data = JSON.parse(res);
    
            if (!data.items.length) {
              writeToDatabase('trashbags', user.uid, {
                trashbag: arrayUnion(unmatchedProduct)
              }, () => checkIfAllItemsWereProcessed(itemCount))
              
              if (!existingCategories?.some(category => category.slug === unmatchedProduct.category)) {
                writeToDatabase('productByCategory', unmatchedProduct.category, {
                  slug: unmatchedProduct.category,
                  product: 'none',
                }, undefined)
              }
            } else handleSelectedAlternative(unmatchedProduct, data.items[0], itemCount)
          }
        });
      }
    })
  }

  const handleFileInput = (file) => {
    setIsProcessingData(true)

    const reader = new FileReader()

    reader.onload = (event) => {
      if (!event.target) return

      const contents = event.target.result

      if (typeof contents !== 'string') return

      const lines = contents.split('\n')

      const itemCount = lines.length - 2

      lines.forEach((line, index) => {
        if (!index || index === lines.length - 1) return

        const lineSplit = line.split(/,(?=(?:(?:[^"]*"){2})*[^"]*$)/)
        // TODO fix the mapping below because it's not getting prices
        const item = {
          category: lineSplit[3],
          marketplace: 'Amazon',
          name: lineSplit[2].split('"').join(''),
          orderDate: lineSplit[0],
          orderId: lineSplit[1],
          price: lineSplit[7],
          seller: lineSplit[5].split('"').join(''),
          serial: lineSplit[4].split('"').join(''),
          quantity: lineSplit[8],
        }

        if (item.name) {
          handleUnmatchedProduct({ userId: user.uid, ...item }, itemCount)
        }
      })
    }

    reader.readAsText(file)
  }

  return (
    <Button
      variant="contained"
      component="label"
      color='secondary'
      sx={{
        width: isProcessingData ? '100%' : '160px',
      }}
      disabled={isProcessingData}
    >
      <Typography sx={{ fontSize: '24px', textTransform: 'none' }}>{isProcessingData ? 'Processing. Don\'t leave the page!' : 'Upload File'}</Typography>

      <input
        type="file"
        hidden
        onChange={({ target }: { target: HTMLInputElement | HTMLTextAreaElement }) => {
          if (isHTMLInputElement(target) && target.files?.length) {
            const inputFile = target.files[0]

            handleFileInput(inputFile)
          }
        }}
      />
    </Button>
  )
}

export default UploadCSV