import React, { useState } from 'react'

import { Menu as MenuIcon } from 'react-feather'

import { AppBar, Box, Button, Container, Divider, IconButton, Link, Menu, MenuItem, Toolbar, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

import LogoLong from '../assets/logo_long.png'
import Logo from '../assets/logo.png'
import { GreenUpUser } from '../types'

const LogoBox = styled(Box)`
  a {
    alignItems: center;
    display: flex;
    height: 100%;
  }
`

interface NavbarProps {
  handleLoginClick: () => void
  isLoginPending: boolean
  user?: GreenUpUser
  signOutUser: () => void,
  setUser: React.Dispatch<React.SetStateAction<GreenUpUser | undefined>>,
  navColor: string,
  isDashboard?: boolean,
}

const Navbar = ({
  handleLoginClick,
  isLoginPending,
  user,
  signOutUser,
  setUser,
  navColor,
  isDashboard = false,
}: NavbarProps) => {
  const [isNavMenuOpen, setIsNavMenuOpen] = useState(false)
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false)
  const [menuAnchor, setMenuAnchor] = useState<EventTarget & HTMLButtonElement>()
  const [navAnchor, setNavAnchor] = useState<EventTarget & HTMLButtonElement>()

  const handleSignOut = () => {
    setUser(undefined)
    signOutUser()
  }

  return (
    <AppBar elevation={isDashboard ? 0 : 1} sx={{ bgcolor: navColor, height: isDashboard ? '128px' : '80px', transition: '300ms all', alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
      <Toolbar sx={{ alignItems: 'center', display: 'flex', width: '100%' }}>
        <Container sx={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'space-between',
          margin: isDashboard ? 0 : undefined,
          padding: isDashboard ? 0 : undefined,
          maxWidth: isDashboard ? '100% !important' : undefined,
        }}>
          <LogoBox display='flex' alignItems='center'>
            <Link href='/'>
              <Box display='flex' alignItems='center'>
                <img height={isDashboard ? '56px' : '32px'} src={isDashboard ? Logo : LogoLong} alt='GreenUp' />
                {isDashboard && <Typography marginLeft='12px' color='secondary.main' sx={{ fontSize: '32px' }}>BETA</Typography>}
              </Box>
            </Link>
          </LogoBox>
          {isDashboard ? (
            <Box>
             {user && <Typography variant='h3'>{`Welcome ${user.name.first}`}</Typography>}
            </Box>
          ) : (
            <Box alignItems='center' display='flex' gap='40px'>
              <Button href='/about-us'>About</Button>
              <Button href='/refer'>Refer</Button>
              <Button href='/faq'>FAQ</Button>
            </Box>
          )}
          {user && (
            <Box alignItems='center' gap='40px' sx={{ display: { xs: 'none', md: 'flex' } }}>
              <Box>
                    <Box alignItems='center' display='flex'>
                      <Typography color='primary.main'>Welcome,</Typography>
                      <Button
                        id='user-button'
                        aria-controls={isUserMenuOpen ? 'user-menu' : undefined}
                        aria-haspopup={true}
                        aria-expanded={isUserMenuOpen ? true : undefined}
                        onClick={(event) => {
                          setMenuAnchor(event.currentTarget)
                          setIsUserMenuOpen(true)
                        }}
                        variant='text'
                      >
                          {isDashboard ? (
                            <Box borderRadius='50%' height='64px' width='64px' sx={{
                              backgroundImage: `url(${user.profileImgUrl})`
                            }} />
                          ) : (
                            <Typography textTransform='capitalize' fontWeight='600'>{user.name.first}</Typography>
                          )}
                      </Button>
                    </Box>
                    <Menu
                      id='user-menu'
                      anchorEl={menuAnchor}
                      open={isUserMenuOpen}
                      onClose={() => setIsUserMenuOpen(false)}
                      MenuListProps={{
                        'aria-labelledby': 'user-button',
                      }}
                    >
                      <MenuItem onClick={handleSignOut}>Log out</MenuItem>
                    </Menu>
                  </Box>
            </Box>
          )}

          <Box marginLeft='auto' sx={{display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={(event) => {
                setNavAnchor(event.currentTarget)
                setIsNavMenuOpen(true)
              }}
              color="inherit"
            >
              <MenuIcon color='white' />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={navAnchor}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={isNavMenuOpen}
              onClose={() => setIsNavMenuOpen(false)}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              <Box display='flex' flexDirection='column'>
                <Button href='/about-us' onClick={() => setIsNavMenuOpen(false)}><Typography color='secondary.main'>About</Typography></Button>
                <Button href='/refer' onClick={() => setIsNavMenuOpen(false)}><Typography color='secondary.main'>Refer</Typography></Button>
                <Button href='/faq' onClick={() => setIsNavMenuOpen(false)}><Typography color='secondary.main'>FAQ</Typography></Button>
                {user && (
                  <>
                    <Divider />
                    <Button onClick={handleSignOut}><Typography color='secondary.main'>Log out</Typography></Button>
                  </>
                )}
              </Box>
            </Menu>
          </Box>
        </Container>
      </Toolbar>
    </AppBar>
  )
}

export default Navbar